import SiteSearch from '../components/site/siteSearch';
import Tabs from '../components/widgets/tabs';
import Forms from '../components/widgets/forms';
import OverlayBlur from '../components/site/overlayBlur';
import Nav from '../components/site/nav';
import CookieBanner from '../components/site/cookieBanner';
import PasswordReveal from '../components/site/passwordReveal';
import Carousel from '../components/widgets/carousel';
import PanelReveal from '../components/widgets/panel-reveal';
import CardReveal from '../components/widgets/card-reveal';

const classes = {
    siteSearchForm: ".js-search-form",
    tabs: ".js-tabs",
    forms: ".js-form",
    overlayMain: ".js-overlay--blur",
    nav: ".js-nav--sub",
    passwordReveal: ".js-reveal-pw",
    glide: ".js-glide",
    panelReveal: ".js-panel-reveal",
    cardReveal: ".js-card-reveal"
};

document.addEventListener("DOMContentLoaded", function (event:any) {
    if (document.querySelector(classes.siteSearchForm)) {
        SiteSearch.init();
    }

    if (document.querySelector(classes.tabs)) {
        Tabs.init();
    }

    if (document.querySelector(classes.overlayMain)) {
        OverlayBlur.init();
    }

    if (document.querySelector(classes.nav)) {
        Nav.init();
    }

    if (document.querySelector(classes.passwordReveal)) {
        PasswordReveal.init();
    }

    if (document.querySelector(classes.forms)) {
        Forms.init();
    }

    if (document.querySelector(classes.glide)) {
        Carousel.init();
    }

    if (document.querySelector(classes.panelReveal)) {
        PanelReveal.init();
    }

    if (document.querySelector(classes.cardReveal)) {
        CardReveal.init();
    }

    CookieBanner.init();
});