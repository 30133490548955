class PasswordReveal {
    static init() {
        const components: NodeListOf<HTMLElement> = document.querySelectorAll(".js-reveal-pw");

        if (components.length) {
            components.forEach((item: HTMLElement, index: number) => {
                item.addEventListener("click", (e: Event) => {
                    if (e) {
                        const currentTarget = e.currentTarget as HTMLElement;
                        const target: HTMLInputElement = document.getElementById(currentTarget.dataset.target) as HTMLInputElement;

                        if (currentTarget && target) {
                            currentTarget.innerHTML = currentTarget.innerHTML === "visibility_off" ? "visibility" : "visibility_off";
                            currentTarget.innerHTML === "visibility" ? target.type = "text" : target.type = "password";
                        }
                    }
                });
            });
        }
    }
}

export default PasswordReveal;