class OverlayBlur {
    static overlayBlur: Element;

    static init() {
        this.overlayBlur = document.querySelector('.js-overlay--blur');
    }

    static show() {
        if (this.overlayBlur) {
            this.overlayBlur.classList.remove('inactive');
            this.overlayBlur.classList.add('active');
        }
    }

    static hide() {
        if (this.overlayBlur) {
            this.overlayBlur.classList.add('inactive');
            this.overlayBlur.classList.remove('active');
        }
    }
}

export default OverlayBlur;