class PanelReveal {
    static init() {
        const revealBtns: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.js-panel-reveal');

        if (revealBtns) {
            revealBtns.forEach(btn => {
                btn.addEventListener('click', (e: Event) => {
                    const button = e.target as HTMLButtonElement;
                    const target: string = button.dataset.revealTarget;

                    if (target) {
                        const revealPanel: HTMLElement = document.querySelector('[data-reveal-id="' + target + '"]');

                        if (revealPanel) {
                            revealPanel.classList.toggle('show');
                        }
                    }

                })        
            });

        }
    }
}

export default PanelReveal;