import CommonFunctions from "../../common/commonFunctions";

interface ValidityState {
    valueMissing: boolean;
    typeMismatch: boolean;
    patternMismatch: boolean;
    tooLong: boolean;
    tooShort: boolean;
    rangeUnderflow: boolean;
    rangeOverflow: boolean;
    stepMismatch: boolean;
    badInput: boolean;
    customError: boolean;
    valid: boolean;
}

// Union type for HTML form elements that have a validity property
type ValidatableElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

class Forms {
    static init() {
        const forms: any = document.querySelectorAll('.js-form');
        const btnsReset = document.querySelectorAll('.js-form-btn-reset') as NodeListOf<HTMLButtonElement>;

        Array.from(forms).forEach((form: any) => {
            // Add event listeners for validation
            const inputs = form.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
            const textAreas = form.querySelectorAll('textarea') as NodeListOf<HTMLTextAreaElement>;
            const selects = form.querySelectorAll('select') as NodeListOf<HTMLSelectElement>;

            inputs.forEach(ele => { addInputEventListeners(ele); });
            textAreas.forEach(ele => { addInputEventListeners(ele); });
            selects.forEach(ele => { addInputEventListeners(ele); });

            // Handle form submission
            form.addEventListener('submit', (event: Event) => {
                if (!form.checkValidity()) {
                    event.preventDefault(); // Prevent form submission if invalid
                }
            });
        });

        if (btnsReset) {
            btnsReset.forEach((btn: HTMLButtonElement) => {
                btn.addEventListener("click", (e: Event) => {
                    resetForm(btn, e);
                });
            });
        }
    }
}

function resetForm(btn: HTMLButtonElement, e: Event) {
    e.preventDefault();
    const form = btn.closest("form");

    if (form) {
        const inputs = form.querySelectorAll("input, select, textarea") as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

        inputs.forEach((input) => {
            if (input instanceof HTMLInputElement) {
                if (input.type === "checkbox" || input.type === "radio") {
                    input.checked = false;
                } else {
                    input.value = "";
                }
            } else if (input instanceof HTMLSelectElement || input instanceof HTMLTextAreaElement) {
                input.value = "";
            }
        });

        // Set focus on the first field
        const firstField = form.querySelector("input, select, textarea") as (HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement);

        if (firstField) {
            firstField.focus();
        }
    }
}

function addInputEventListeners(input: ValidatableElement) {
    input.addEventListener('input', () => {
        setCustomValidityMessage(input, '');
    });

    input.addEventListener('invalid', () => {
        let message = '';
        const name = CommonFunctions.breakCamelCase(input.id);

        if (input.validity.valueMissing) {
            message = `${name} is required.`;
        } else if (input.validity.typeMismatch) {
            message = `Please enter a valid ${input.type}.`;
        } else if (input.validity.tooShort && !(input instanceof HTMLSelectElement)) {
            message = `Your ${name} must be at least ${input.minLength} characters long.`;
        } else if (input.validity.tooLong && !(input instanceof HTMLSelectElement)) {
            message = `Your ${name} must be a maximum of ${input.maxLength} characters long.`;
        }
        else if (input.validity.patternMismatch) {
            message = `${name} has an incorrect format.`;
        }

        setCustomValidityMessage(input, message);
    });
}

// Function to set custom validity messages
function setCustomValidityMessage(input: ValidatableElement, message: string): void {
    // Dow we have an error message label
    const errorSpan = document.getElementById(`${input.id}Error`) as HTMLSpanElement;

    if (errorSpan) {
        if (message) {
            errorSpan.textContent = message;
        } else {
            errorSpan.textContent = '';
        }
    }

    if (message) {
        input.setCustomValidity(message);
    } else {
        input.setCustomValidity('');
    }
}

export default Forms;