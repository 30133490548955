class Tabs {
    static init() {
        const tabSets: any = document.querySelectorAll('.js-tabs');

        Array.from(tabSets).forEach((tabSet: any) => {
            const tabBtns: NodeListOf<Element> = document.querySelectorAll('.js-tabs-tab');
            const tabs: NodeListOf<Element> = document.querySelectorAll('.js-tabs-tab-content');

            if (tabBtns) {
                Array.from(tabBtns).forEach((btn: HTMLButtonElement) => {
                    btn.onclick = (e: any) => {
                        tabBtns.forEach(x => x.classList.remove('active'));

                        btn.classList.add('active');

                        const tabContent: Element = tabSet.querySelector(`[data-tab='${btn.dataset.target}']`);

                        if (tabContent) {
                            tabs.forEach(x => x.classList.remove('active'));

                            tabContent.classList.add('active');
                        }
                    }
                });
            }
        });
    }
}

export default Tabs;