class SiteSearch {
    static init() {
        const siteSearches: HTMLElement = document.querySelector('.js-site-search');
        const forms: any = siteSearches.querySelector('.js-search-form');
        const textBoxes: HTMLInputElement = forms.querySelector('.js-search-text');
        const searchBtns: any = document.getElementsByClassName('js-search-btn');

        forms.onsubmit = () => {
            const searchText = textBoxes.value;
            forms.action = `${forms.action}?query=${searchText}`;
        };

        if (searchBtns) {
            Array.from(searchBtns).forEach((btn: any) => {
                btn.onclick = (e: any) => {
                    textBoxes.focus();
                }
            });
        }
    }
}

export default SiteSearch;