import CookieService from '../../services/cookieService';

class CookieBanner {
    static init() {

        const cookiePolicyNames = {
            accepted: 'cpac',
            essential: 'cpes'
        };

        if (CookieService.get(cookiePolicyNames.accepted) == null && CookieService.get(cookiePolicyNames.essential) == null) {
            const activeClass: string = 'active';
            const activeHideClass: string = 'active--hide';
            const btnCookieAcc: any = document.querySelector('.js-btn-cookie-acc');
            const btnCookieDec: any = document.querySelector('.js-btn-cookie-dec');
            const cookieBanner: HTMLElement = document.querySelector('.js-cookie-banner');

            if (cookieBanner) {
                cookieBanner.classList.add(activeClass);
            }

            if (btnCookieAcc) {
                btnCookieAcc.onclick = (e: any) => {
                    CookieService.set(cookiePolicyNames.accepted, '', 9999);
                    this.updateDataLayer('all');

                    cookieBanner.classList.add(activeHideClass);
                }
            }

            if (btnCookieDec) {
                btnCookieDec.onclick = (e: any) => {
                    CookieService.set(cookiePolicyNames.essential, '', 30);
                    this.updateDataLayer('essential');
                    cookieBanner.classList.add(activeHideClass);
                }
            }
        }
    }

    static updateDataLayer(cookieSetting: string) {
        if (window.dataLayer) {
            window.dataLayer.push({ cookies: cookieSetting });
        }
    }
}

export default CookieBanner;
