export default class CookieService {
    static get(cookieName: string) {
        const nameEncoded: string = encodeURIComponent(cookieName) + '=';
        const cookieArray: string[] = document.cookie.split(';');

        for (let i: number = 0; i < cookieArray.length; i++) {
            const cookie: string = cookieArray[i].trim();

            if (cookie.indexOf(nameEncoded) === 0) {
                return decodeURIComponent(cookie.substring(nameEncoded.length, cookie.length));
            }
        }

        return null;
    }

    static set(cookieName: string, cookieValue: string, daysToExpire: number) {
        const expirationDate: Date = new Date();
        expirationDate.setDate(expirationDate.getDate() + daysToExpire);

        const cookieString: string =
            encodeURIComponent(cookieName) +
            '=' +
            encodeURIComponent(cookieValue) +
            '; expires=' +
            expirationDate.toUTCString() +
            '; path=/';

        document.cookie = cookieString;
    }

    static delete(cookieName: string) {
        const expirationDate: Date = new Date();
        expirationDate.setTime(expirationDate.getTime() - 1);
        const cookieString: string = encodeURIComponent(cookieName) + '=; expires=' + expirationDate.toUTCString() + '; path=/';
        document.cookie = cookieString;
    }
}