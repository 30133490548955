class CardReveal {
    static init() {
        const revealTargets: NodeListOf<HTMLElement> = document.querySelectorAll('.js-card-reveal');

        const observerOptions = {
            root: null, // Use the viewport as the container
            rootMargin: '100px',
            threshold: 1 // 10% of the element is visible
        };

        if (revealTargets) {
            revealTargets.forEach(rt => {
                rt.parentElement.classList.remove('visible');

                const observerCallback = (entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            entry.target.parentElement.classList.add('visible');
                        }
                    });
                };

                const observer = new IntersectionObserver(observerCallback, observerOptions);
                observer.observe(rt);
            });
        }
    }
}

export default CardReveal;