import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm.js'

class Carousel {
    static init() {
        const carousels: NodeListOf<HTMLElement> = document.querySelectorAll('.js-glide');

        if (carousels) {
            carousels.forEach(c => {
                const carouselSettings: string = c.dataset.glide;

                if (carouselSettings) {
                    let jsonString: string = carouselSettings.replace(/'/g, '"');
                    jsonString = jsonString.replace(/(\w+):/g, '"$1":');

                    new Glide(c, JSON.parse(jsonString)).mount({ Controls, Breakpoints });
                }
                else {
                    new Glide(c).mount({ Controls, Breakpoints });
                }                
            });

        }
    }
}

export default Carousel;