import OverlayBlur from './overlayBlur';

class Nav {
    static init() {
        let subnavs: NodeListOf<Element> = document.querySelectorAll('.js-nav--sub');

        if (subnavs) {
            subnavs.forEach(subnav => {
                const subNavEle = subnav as HTMLElement;
                subNavEle.onmouseover = () => {
                    OverlayBlur.show();
                };

                subNavEle.onmouseout = () => {
                    OverlayBlur.hide();
                };
            });

            // When doc resizes, auto close menu
            window.addEventListener("resize", () => {
                document.getElementById('mn').classList.remove('mn-active');
            });
        }
    }
}

export default Nav;